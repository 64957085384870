.highlightleft {
    padding-left: 1em;
    border-left: 3px solid var(--primary);
}

.workplace {
    margin-top: 2.5em;
}

.title {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 1.2;
}

p.lead {
    font-weight: 400;
}

.subheading {
    font-size: 1.25em;
    color: var(--gray);
}

.subtitle {
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 1.2;
    color: var(--gray);
}

.color {
    color: var(--primary);
}

@media print {
    .col-print-1, .col-print-2, .col-print-3, .col-print-4, .col-print-5, .col-print-6, .col-print-7, .col-print-8,
    .col-print-9, .col-print-10, .col-print-11, .col-print-12, .col-print-auto {
        float: left;
    }
    .col-print-12 {
        width: 100%;
    }
    .col-print-11 {
        width: 91.66666667%;
    }
    .col-print-10 {
        width: 83.33333333%;
    }
    .col-print-9 {
        width: 75%;
    }
    .col-print-8 {
        width: 66.66666667%;
    }
    .col-print-7 {
        width: 58.33333333%;
    }
    .col-print-6 {
        width: 50%;
    }
    .col-print-5 {
        width: 41.66666667%;
    }
    .col-print-4 {
        width: 33.33333333%;
    }
    .col-print-3 {
        width: 25%;
    }
    .col-print-2 {
        width: 16.66666667%;
    }
    .col-print-1 {
        width: 8.33333333%;
    }
    .col-print-auto {
        width: auto;
    }

    @page {
        size: A4 portrait;
        width: 1200px;
        margin-left: 0;
        margin-right: 1cm;
    }
    body {
        zoom: 70%;
    }
    div#page-content {
        margin-right: 10cm;
        width: 100%;
        max-width: 95%;
    }
    .workplace {
        page-break-inside: avoid;
    }
}
