.navbar-tbc {
    background-color: var(--primary);
    font-weight: 800;
}

.navbar-tbc .navbar-brand a {
    margin-right: 0;
}

.navbar {
    align-items: center !important;
    justify-content: center !important;
    color: var(--primary);
}

.navbar .nav-item a {
    color: rgba(255, 255, 255, 0.5);
    padding: 0.5rem;
    text-decoration: none;
    display: block;
}

nav .img-profile {
    max-height: 50px;
    max-width: 50px;

    border-width: 0.25rem;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
}

nav.navbar-tbc a:hover {
    color: var(--light);
}