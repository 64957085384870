
html:root {
    --primary: #17a2b8 !important;
    --light: #f8f9fa !important;
    --gray: #6c757d !important;
}

.container .text-primary {
    color: var(--primary) !important;
}

.container a, .container a:hover {
    color: var(--primary) ;
}

blockquote {
    border-left: 3px solid var(--primary);
    padding-left: 1em;
}

blockquote cite {
    display: block;
    margin-left: .5em;
    margin-top: .5em;
}
