.intro nav .img-profile {
    max-width: 10rem;
    max-height: 10rem;

    border-width: 0.5rem;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
}

.intro {
    min-height: 100vh;
    height: auto;
    padding: 2em;
}

.intro-content {
    margin-top: auto;
    margin-bottom: auto;
    display: block;
}

.intro-left {
    background-color: var(--primary);
    color: var(--light);
    font-weight: 800;
}

.intro-right {
    color: var(--dark);
}

.intro-left nav a:hover {
    color: var(--light);
}